/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { kebabCase } from 'lodash';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../layouts/Default';
import Section from '../components/Section';
import TableOfContents from '../components/TableOfContents';

const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    backgroundColor: '#ffff',

  },
  grid: {
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
    display: 'grid',
    gridTemplateColumns: 'repeat(12,1fr)',
    gridGap: '0 32px',
    maxWidth: '1120px',
    gridTemplateRows: 'max-content',
    backgroundColor: '#ffffff',
  },
  sidebar: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      gridColumn: '1 / -1',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginBottom: '64px',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginBottom: '32px',
    },
    gridColumn: '1 / 4',
  },
  main: {
    gridColumn: '4 / -1',
  },
  author: {
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
    [theme.breakpoints.down('xs')]: {
      order: 2,
      marginTop: 24,
    },
    display: 'flex',
    marginTop: theme.spacing(2),
  },
  authorImage: {
    width: 50,
    height: 50,
    borderRadius: 50,
  },
  authorInfo: {
    marginLeft: theme.spacing(2),
    color: 'inherit',
    fontWeight: 500,
  },
  authorDescription: {
    color: theme.palette.text.secondary,
    fontWeight: 'normal',
  },
  toc: {
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      top: 0,
      width: 'calc(50% - 32px)',
      position: 'relative',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      order: 1,
    },
    marginTop: '64px',
    top: '32px',
    position: 'sticky',
  },
  tocButton: {
    [theme.breakpoints.down('md')]: {
      fontSize: 'inherit',
      border: 0,
      display: 'flex',
      color: '#282828',
      background: 'inherit',
      borderBottom: '3px solid #282828',
      cursor: 'pointer',
      padding: '11px 0',
      width: '100%',
      textAlign: 'left',
      outline: 0,
    },
    display: 'none',
  },
  tocButtonIcon: {
    marginLeft: theme.spacing(1),
    width: 20,
    height: 20,
  },
  tocItems: {
    [theme.breakpoints.down('md')]: {
      display: (props) => (props.isDropdownOpen ? 'block' : 'none'),
      position: 'absolute',
      zIndex: 2,
      width: '100%',
      minWidth: 280,
      marginTop: '-3px',
      background: '#fff',
      padding: '24px',
      border: '3px solid #282828',
      right: 0,
    },
  },
  tags: {
    marginTop: theme.spacing(8),
  },
  navigation: {
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / -1',
      gridTemplateColumns: 'auto 1fr',
      gridGap: 32,
      alignItems: 'stretch',
    },
    display: 'grid',
    gridTemplateColumns: 'repeat(8,1fr)',
    gridGap: '0 32px',
    alignItems: 'start',
    gridColumn: '5 / -1',
    marginTop: theme.spacing(2),
  },
  navigationLink: {
    '&:hover': {
      background: '#F5F5F5',
      textDecoration: 'none',
      color: '#3273dc',
    },
  },
  navigationPrev: {
    padding: theme.spacing(3),
    background: 'inherit',
    width: '100%',
    gridColumn: '1 / span 4',
    marginLeft: `-${theme.spacing(3)}px`,
  },
  navigationNext: {
    padding: theme.spacing(3),
    background: 'inherit',
    width: '100%',
    gridColumn: '5 / span 4',
  },
  navigationText: {
    fontSize: 20,
    fontWeight: 700,
  },
  navigationSubtext: {
    color: theme.palette.text.secondary,
    marginBottom: 4,
  },
}));

export const BlogPostTemplate = ({
  helmet,
  location,
  post,
  pageContext,
}) => {
  const { body, frontmatter, tableOfContents } = post;
  const {
    description, tags, title,
    author, authorDescription, authorImage,
    disableTableOfContents,
  } = frontmatter;
  const toc = !disableTableOfContents && tableOfContents.items;
  const { previous, next } = pageContext;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const classes = useStyles({ isDropdownOpen });
  return (
    <React.Fragment>
      <Section className={`${classes.section} content`}>
        {helmet || ''}
        <div className={classes.grid}>
          <div className={classes.sidebar}>
            <div className={classes.author}>
              {
                authorImage && authorImage.childImageSharp && authorImage.childImageSharp.gatsbyImageData
                && <GatsbyImage
                  image={authorImage.childImageSharp.gatsbyImageData}
                  className={classes.authorImage} />
              }
              <div className={classes.authorInfo}>
                {author}
                <div className={classes.authorDescription}>
                  {authorDescription}
                </div>
              </div>
            </div>
            {toc && (
              <nav className={classes.toc}>
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className={classes.tocButton}
                >
                  Jump to Section
                  {
                    isDropdownOpen
                      ? <ArrowDropUpIcon className={classes.tocButtonIcon} />
                      : <ArrowDropDownIcon className={classes.tocButtonIcon} />
                  }
                </button>
                <div className={classes.tocItems}>
                  <TableOfContents location={location} page={post} />
                </div>
              </nav>
            )}
          </div>
          <div className={classes.main}>
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <p>{description}</p>
            <MDXRenderer>{body}</MDXRenderer>
            {tags && tags.length ? (
              <div className={classes.tags}>
                <h4>Tags</h4>
                <ul className="taglist">
                  {tags.map((tag) => (
                    <li key={`${tag}tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            <nav className={classes.navigation}>
              {previous && (
                <Link
                  to={previous.fields.slug}
                  rel="prev"
                  className={`${classes.navigationLink} ${classes.navigationPrev}`}
                >
                  <div className={classes.navigationSubtext}>
                    Previous
                  </div>
                  <div
                    className={classes.navigationText}
                  >
                    {previous.frontmatter.title}
                  </div>
                </Link>
              )}
              {next && (
                <Link
                  to={next.fields.slug}
                  rel="next"
                  className={`${classes.navigationLink} ${classes.navigationNext}`}
                >
                  <div className={classes.navigationSubtext}>
                    Next
                  </div>
                  <div
                    className={classes.navigationText}
                  >
                    {next.frontmatter.title}
                  </div>
                </Link>
              )}
            </nav>
          </div>
        </div>
      </Section>
    </React.Fragment>
  );
};

BlogPostTemplate.propTypes = {
  body: PropTypes.node.isRequired,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data, pageContext, location }) => {
  const { mdx: post } = data;
  return (
    <Layout>
      <BlogPostTemplate
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        location={location}
        post={post}
        pageContext={pageContext}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`query BlogPostByID($id: String!) {
  mdx(id: {eq: $id}) {
    id
    tableOfContents
    frontmatter {
      date(formatString: "MMMM DD, YYYY")
      title
      description
      author
      authorDescription
      authorImage {
        childImageSharp {
          gatsbyImageData(width: 50, quality: 100, layout: CONSTRAINED)
        }
      }
      tags
      disableTableOfContents
      tableOfContentsDepth
    }
    body
  }
}
`;
