/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    color: `${theme.palette.text.secondary} !important`,
    fontSize: '0.875rem !important',
    textTransform: 'uppercase',
    marginBottom: '1rem !important',
  },
  list: {
    listStyle: 'none !important',
    margin: '0 !important',
  },
  sublist: {
    listStyle: 'none !important',
    marginLeft: `${theme.spacing(2)}px !important`,
    marginTop: `${theme.spacing(2)}px !important`,
  },
  listItem: {
    marginBottom: `${theme.spacing(2)}px !important`,
  },
  link: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
    '&:hover': {
      borderBottom: `3px solid ${theme.palette.text.secondary}`,
    },
  },
  activeLink: {
    fontWeight: 500,
    color: theme.palette.text.primary,
    borderBottom: `3px solid ${theme.palette.text.primary}`,
  },
}));

function isUnderDepthLimit(depth, maxDepth) {
  if (maxDepth === null) {
    // if no maxDepth is passed in, continue to render more items
    return true;
  }
  return depth < maxDepth;
}

// depth and maxDepth are used to figure out how many bullets deep to render in the ToC sidebar, if no
// max depth is set via the tableOfContentsDepth field in the frontmatter, all headings will be rendered
function createItems(items, location, depth, maxDepth, classes) {
  return (
    items
    && items.map((item, index) => (
      <li
        className={classes.listItem}
        key={location.pathname + (item.url || `${depth}-${index}`)}
      >
        {item.url && (
          <Link
            className={classes.link}
            getProps={({ href, linkLocation }) => (linkLocation && linkLocation.href && linkLocation.href.includes(href)
              ? {
                className: classes.activeLink,
              }
              : null)
            }
            to={location.pathname + item.url}
          >
            {item.title}
          </Link>
        )}
        {item.items && isUnderDepthLimit(depth, maxDepth) && (
            <ul className={classes.sublist}>
            {createItems(item.items, location, depth + 1, maxDepth, classes)}
          </ul>
        )}
      </li>
    ))
  );
}

function TableOfContents({ page, location }) {
  const classes = useStyles();
  return page.tableOfContents.items ? (
    <div>
      <h2 className={classes.title}>
        Table of Contents
      </h2>
      <ul className={classes.list}>
        {createItems(
          page.tableOfContents.items,
          location,
          1,
          page.frontmatter.tableOfContentsDepth,
          classes,
        )}
      </ul>
    </div>
  ) : null;
}

export default TableOfContents;
